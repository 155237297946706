import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { SpinnerService } from 'src/app/core/services/spinner/spinner.service';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  constructor(private spinner: SpinnerService, private notificationService: NotificationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      !window.location.href.includes('dashboards/list') &&
      (!window.location.href.includes('ptr') ||
        (window.location.href.includes('ptr') &&
          (localStorage.getItem('ptrView') !== 'gantt' || localStorage.getItem('ptrAside') === 'open')))
    ) {
      this.spinner.show();
    }

    return next.handle(request).pipe(
      map((res: any) => {
        if (res.type === 4 && res.status === 200 && res.body?.message) {
          this.notificationService.showSuccess(res.body.message);
        }
        if (res.type === 4 && res.status === 290 && res.body?.message) {
          res.body.error = true;
          this.notificationService.showWarning(res.body.message);
        }
        return res;
      }),
      finalize(() => this.spinner.hide())
    ) as Observable<HttpEvent<any>>;
  }
}
