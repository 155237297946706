import packageJson from '../../package.json';

export const environment = {
  production: true,
  VERSION: packageJson.version,
  aragorn_url: 'https://aragorn.dev.app.gconsulting.es',
  root_api: 'https://aragorn.dev.app.gconsulting.es/api',
  logout_url: 'https://aragorn.dev.app.gconsulting.es/auth/logout',
  assets_url: 'https://gconsulting-assets.s3.eu-west-1.amazonaws.com/env/dev/gandalf',
  ganttChartViewLicense: 'SINGLAR INNOVACION SL:69D4896F:A5AEA245:1770242400000:40CE7973',
  name: 'dev',
  cache: {
    expiration_time: 0,
    default_preffix: 'cache_preffix',
  },
  language: {
    default: 'es',
    languages: [
      {
        code: 'en',
        name: 'English',
        icon: 'flag-icon-gb',
        dateFormat: 'yyyy-MM-dd',
        dateTimeFormat: 'yyyy-MM-dd hh:mm a',
        ISOdateFormat: 'yyyy-mm-dd',
        ISOdateTimeFormat: 'yyyy-mm-dd hh:mm a',
      },
      {
        code: 'es',
        name: 'Español',
        icon: 'flag-icon-es',
        dateFormat: 'dd/MM/yyyy',
        dateTimeFormat: 'dd/MM/yyyy HH:mm',
        ISOdateFormat: 'dd/mm/yyyy',
        ISOdateTimeFormat: 'dd/mm/yyyy hh:mm',
      },
      // {
      //   code: 'de',
      //   name: 'Deutsche',
      //   icon: 'flag-icon-de',
      //   dateFormat: 'dd.MM.yyyy',
      //   dateTimeFormat: 'dd.MM.yyyy hh:mm',
      //   ISOdateFormat: 'dd.mm.yyyy',
      //   ISOdateTimeFormat: 'dd.mm.yyyy hh:mm',
      // },
      // {
      //   code: 'nl',
      //   name: 'Nederlands',
      //   icon: 'flag-icon-nl',
      //   dateFormat: 'MM-dd-yyyy',
      //   dateTimeFormat: 'MM-dd-yyyy hh:mm',
      //   ISOdateFormat: 'mm-dd-yyyy',
      //   ISOdateTimeFormat: 'mm-dd-yyyy hh:mm',
      // },
      // {
      //   code: 'pt',
      //   name: 'Português',
      //   icon: 'flag-icon-pt',
      //   dateFormat: 'dd/MM/yyyy',
      //   dateTimeFormat: 'dd/MM/yyyy hh:mm',
      //   ISOdateFormat: 'dd/mm/yyyy',
      //   ISOdateTimeFormat: 'dd/mm/yyyy hh:mm',
      // },
    ],
  },
  mixpanel: {
    //token: '1956f21f5457e4b4220d62a80f85282e',
    token: '450bebceb6ac04bcc29cf799e9759949',
    enabled: true,
  },
};
